import { ISODateTime, UUID } from "api/types";
import { PartialOf } from "typeUtilities";
import { Assign } from "utility-types";
import { ReceivedBySourcesType, ReleasedBySourcesType } from "./enums";

export enum LongHeldPackageTab {
  "in-stock" = "in-stock",
  "not-in-stock" = "not-in-stock",
}

export type LongHeldPackage = {
  createdAt: ISODateTime;
  id: UUID;
  order: {
    id: number;
    signature: string;
    externalId: string;
  } | null;
  index: {
    id: number;
    name: string;
    productName: string;
  } | null;
  customer: {
    id: number;
    name: string;
  } | null;
  releasedAt: ISODateTime | null;
  settledAt: ISODateTime | null;
  daysInStock: string;
  packages: {
    id: UUID;
    uniqueCode: string;
    receivedAt: ISODateTime | null;
    releasedAt: ISODateTime | null;
    daysInStock: string;
    settledAt: ISODateTime | null;
    isDelivered: boolean;
    location: string;
    receivedBySource: {
      id: UUID;
      type: ReceivedBySourcesType;
      details: {
        id: UUID;
        signature: string;
      };
    } | null;
    releasedBySource: {
      id: UUID;
      type: ReleasedBySourcesType;
      details: { id: UUID; signature: string };
    } | null;
  }[];
};

export type BulkUpdateLongHeldPackage = Assign<
  PartialOf<LongHeldPackage>,
  {
    objects: UUID[];
  }
>;
