import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { RouteComponentProps } from "react-router";
import { TradingDocument, TradingDocumentTab } from "api/trading-documents/models";
import { useQuery, useSelector, useToggle } from "hooks";
import { getAmountSummarySearch, getSearch } from "../shared/utils/getSearch";
import { useFinancesAmountSummary, useTradingDocuments } from "api/trading-documents/hooks";
import { useSalesInvoicesColumns } from "./useSalesInvoicesColumns";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { PageHeader } from "components/common";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useTradingDocumentsSettings } from "api/milo-settings/hooks";
import { getTabs } from "../shared/utils/getTabs";
import { salesInvoices } from "components/common/moduleNavigation/moduleConfig/finances/routes/salesInvoices";
import { CurrentlyPrintedReceiptsModal } from "./components/currentlyPrintedReceiptsModal/CurrentlyPrintedReceiptsModal";
import { LastPrintedReceiptsModal } from "./components/lastPrintedReceiptsModal/LastPrintedReceiptsModal";
import { AmountSummaryModal } from "../shared/components/amountSummaryModal/AmountSummaryModal";
import { FinancialReportModal } from "./components/financialReportModal/FinancialReportModal";
import { CreateInvoiceModal } from "./components/createInvoiceModal/CreateInvoiceModal";
import { dateUtils } from "utilities";
import { startOfDay, subDays } from "date-fns";
import { BottomBarOptions } from "./components/BottomBarOptions";
import { Table } from "components/miloDesignSystem/molecules/table/Table";
import { RightPanel } from "./rightPanel/RightPanel";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { LedgerAccountSelector } from "../shared/tableToolbar/ledgerAccountSelector/LedgerAccountSelector";
import { WaproFakirXml } from "../shared/tableToolbar/waproFakirXml/WaproFakirXml";
import { TradingDocumentPdf } from "../shared/tableToolbar/tradingDocumentPdf/TradingDocumentPdf";
import { DownloadRevisor } from "../shared/tableToolbar/downloadRevisor/DownloadRevisor";
import { TradingDocumentXml } from "../shared/tableToolbar/tradingDocumentXml/TradingDocumentXml";
import { ConfirmInvoices } from "../shared/tableToolbar/confirmInvoices/ConfirmInvoices";
import { EmailNotifications } from "../shared/tableToolbar/emailNotifications/EmailNotifications";
import { ChangeIssueOrDeliveryInvoiceDate } from "../shared/tableToolbar/changeIssueOrDeliveryInvoiceDate/ChangeIssueOrDeliveryInvoiceDate";
import { FiscalizeReceipts } from "../shared/tableToolbar/fiscalizeReceipts/FiscalizeReceipts";
import { OptimaTradeModuleZip } from "../shared/tableToolbar/optimaTradeModuleZip/OptimaTradeModuleZip";

interface HeaderProps {
  tab: TradingDocumentTab;
}

export const SalesInvoices = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ invoiceType: "SALES", query, tab, type: "INVOICE" });
    const {
      data: tradingDocuments,
      error,
      isLoading,
      isPreviousData,
      pagination,
    } = useTradingDocuments(search);
    const tableMultiSelect = useTableMultiSelect({ rows: tradingDocuments });
    const tableProps = useTableFeatureConnector({
      rows: tradingDocuments,
      withDrawer: "salesInvoices",
      withMultiSelect: tableMultiSelect,
      withPagination: { pagination, defaultPaginationVisibility: true },
    });
    const columns = useSalesInvoicesColumns();

    return (
      <PageWrapper>
        <Header tab={tab} />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<TradingDocument>
            columns={columns}
            error={error}
            isLoading={isLoading || isPreviousData}
            uiSchema={{
              header: {
                backgroundColor: "grey25",
              },
              cell: {
                height: "27",
              },
            }}
            {...tableProps}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <ConfirmInvoices selectedRows={tableMultiSelect.selectedRows} />
            <EmailNotifications selectedRows={tableMultiSelect.selectedRows} />
            <ChangeIssueOrDeliveryInvoiceDate selectedRows={tableMultiSelect.selectedRows} />
            <LedgerAccountSelector selectedRows={tableMultiSelect.selectedRows} />
            <WaproFakirXml selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentPdf invoiceType="SALES" selectedRows={tableMultiSelect.selectedRows} />
            <DownloadRevisor selectedRows={tableMultiSelect.selectedRows} />
            <TradingDocumentXml
              invoiceType="SALES"
              selectedRows={tableMultiSelect.selectedRows}
              type="INVOICE"
            />
            <FiscalizeReceipts
              hasCorrespondingReceipt
              selectedRows={tableMultiSelect.selectedRows}
            />
            <OptimaTradeModuleZip selectedRows={tableMultiSelect.selectedRows} />
          </TableToolbar>
        </div>
      </PageWrapper>
    );
  },
);

const Header = ({ tab }: HeaderProps) => {
  const { query } = useQuery({ exclude: ["panelId", "page"] });
  const search = getAmountSummarySearch({ query, type: "INVOICE", invoiceType: "SALES", tab });
  const currentlyPrintedReceiptsModal = useToggle();
  const lastPrintedReceiptsModal = useToggle();
  const amountSummaryModal = useToggle();
  const financialReportModal = useToggle();
  const createInvoiceModal = useToggle();
  const tradingDocumentsSettingsId = useSelector(
    store => store.partials.finances.tradingDocumentSettings[0].id,
  );
  assertIsDefined(tradingDocumentsSettingsId);
  const {
    data: tradingDocumentSettings,
    isLoading: areSettingsLoading,
  } = useTradingDocumentsSettings({
    id: tradingDocumentsSettingsId,
  });
  const { data: amountSummary, isLoading, error } = useFinancesAmountSummary(search);

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <Tooltip title="Pobierz zestawienie dokumentów handlowych">
              <IconButton
                icon={MdiDownloadFile}
                isLoading={areSettingsLoading}
                onClick={financialReportModal.open}
                variant="transparent"
              />
            </Tooltip>
          </div>
        }
        additionalListParams={{
          type: "INVOICE",
          invoiceType: "SALES",
        }}
        bottomBarButtons={
          <BottomBarOptions
            amountSummary={amountSummary}
            amountSummaryModal={amountSummaryModal}
            currentlyPrintedReceiptsModal={currentlyPrintedReceiptsModal}
            isLoading={isLoading}
            error={error}
            lastPrintedReceiptsModal={lastPrintedReceiptsModal}
          />
        }
        createButton={{
          alt: "utwórz",
          label: "Utwórz fakturę",
          onClick: createInvoiceModal.open,
        }}
        searchInput={{
          label: "Szukaj...",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: dateUtils.formatDateToIso(startOfDay(subDays(new Date(), 7))),
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: dateUtils.formatDateToIso(new Date()),
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${salesInvoices.url}`,
          urlSpan: "list",
        }}
        viewLabel="SALES_INVOICES"
      />
      {currentlyPrintedReceiptsModal.isOpen && (
        <CurrentlyPrintedReceiptsModal close={currentlyPrintedReceiptsModal.close} />
      )}
      {lastPrintedReceiptsModal.isOpen && (
        <LastPrintedReceiptsModal close={lastPrintedReceiptsModal.close} />
      )}
      {amountSummaryModal.isOpen && amountSummary && (
        <AmountSummaryModal amountSummary={amountSummary} close={amountSummaryModal.close} />
      )}
      {financialReportModal.isOpen && (
        <FinancialReportModal
          close={financialReportModal.close}
          tradingDocumentSettings={tradingDocumentSettings}
        />
      )}
      {createInvoiceModal.isOpen && <CreateInvoiceModal close={createInvoiceModal.close} />}
    </div>
  );
};
