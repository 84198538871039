import { useQuery, useStateModal } from "hooks";
import { RouteComponentProps } from "react-router";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { useExternalManufacturingOrdersColumns } from "./useExternalManfacturingOrdersColumns";
import {
  ExternalManufacturerItemsTab,
  ExternalManufacturingOrder,
} from "api/external-manufacturing/models";
import { getSearch } from "./utils/getSearch";
import { getTabs } from "./utils/getTabs";
import { RightPanel } from "./rightPanel/RightPanel";
import { filterFactory, withFilters } from "components/utils/withFilters";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { TableToolbar } from "components/miloDesignSystem/molecules/tableToolbar/TableToolbar";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiForklift } from "components/miloDesignSystem/atoms/icons/MdiForklift";
import { AddOrdersToUnloadingResponse } from "api/orders/models";
import { ResponseModal } from "pages/wms/unloadingsList/rightPanel/components/addOrdersBySignaturesModal/ResponseModal";
import { orderActions } from "api/orders/actions";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { MdiDownloadCsv } from "../../../components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";
import { MdiCancel } from "../../../components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import styles from "../../externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";
import { cx } from "utilities";

export const ExternalManufacturingOrders = withFilters(
  filterFactory(({ FilterType }) => [
    {
      type: FilterType.Search,
      label: "producent",
      name: "manufacturerId",
      searchBy: "manufacturers",
    },
  ]),
  ({ match }: RouteComponentProps<{ tab: ExternalManufacturerItemsTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab });
    const {
      data,
      error,
      isPreviousData,
      isLoading,
      pagination,
    } = externalManufacturingActions.useExternalManufacturingOrders(search);

    const responseModal = useStateModal<AddOrdersToUnloadingResponse>();
    const columns = useExternalManufacturingOrdersColumns();
    const tableMultiSelect = useTableMultiSelect({ rows: data });
    const createUnloadingMutation = orderActions.useCreateUnloadingFromOrders();
    const cancelOrdersMutation = externalManufacturingActions.useChangeCancelStatus();
    const restoreOrdersMutation = externalManufacturingActions.useChangeCancelStatus();

    const tableProps = useTableFeatureConnector({
      rows: data,
      withDrawer: "externalManufacturingOrders",
      withPagination: { pagination, defaultPaginationVisibility: true },
      withMultiSelect: tableMultiSelect,
    });

    return (
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          tabs={{
            list: getTabs(),
            routesRoot: `external-manufacturing/orders`,
            urlSpan: "list",
          }}
          viewLabel="EXTERNAL_MANUFACTURING_ORDERS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<ExternalManufacturingOrder>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
            overrides={() => {
              return {
                row: row => {
                  return {
                    ...tableProps?.overrides?.(),
                    className: cx(
                      {
                        [styles.cancelledRow]:
                          row.isCancelled &&
                          tableMultiSelect.selectedRows.every(
                            selectedRow => selectedRow.id !== row.id,
                          ),
                      },
                      tableProps?.overrides?.().row?.(row).className,
                    ),
                  };
                },
              };
            }}
          />
          <RightPanel />
          <TableToolbar
            close={tableMultiSelect.clear}
            numberOfSelectedItems={tableMultiSelect.selectedRows.length}
          >
            <Tooltip title="Nadaj przesyłki">
              <IconButton
                icon={MdiForklift}
                isLoading={createUnloadingMutation.isLoading}
                variant="transparent"
                theme="dark"
                onClick={() => {
                  createUnloadingMutation.mutate(
                    {
                      orderIds: tableMultiSelect.selectedRows.flatMap(row =>
                        row.orders.map(order => order.id),
                      ),
                    },
                    {
                      onSuccess: payload => {
                        responseModal.open(payload);
                      },
                    },
                  );
                }}
              />
            </Tooltip>
            <FileDownloadHandler
              factoryFn={() =>
                externalManufacturingFileFactory.externalManufacturingOrderCsv(
                  tableMultiSelect.selectedRows.map(order => order.id),
                )
              }
              type="csv"
            >
              {({ download, isLoading }) => (
                <Tooltip title="Pobierz CSV zleceń">
                  <IconButton
                    icon={MdiDownloadCsv}
                    isLoading={isLoading}
                    onClick={download}
                    theme="dark"
                    variant="transparent"
                  />
                </Tooltip>
              )}
            </FileDownloadHandler>
            <Tooltip title="Anuluj wybrane zlecenia">
              <IconButton
                icon={MdiCancel}
                isLoading={cancelOrdersMutation.isLoading}
                onClick={() =>
                  cancelOrdersMutation.mutate({
                    cancel: true,
                    manufacturingOrderIds: tableMultiSelect.selectedRows.map(order => order.id),
                  })
                }
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
            <Tooltip title="Przywróć wybrane zlecenia">
              <IconButton
                icon={MdiRepeat}
                isLoading={restoreOrdersMutation.isLoading}
                onClick={() =>
                  restoreOrdersMutation.mutate({
                    cancel: false,
                    manufacturingOrderIds: tableMultiSelect.selectedRows.map(order => order.id),
                  })
                }
                variant="transparent"
                theme="dark"
              />
            </Tooltip>
          </TableToolbar>
          {responseModal.isOpen && responseModal.state && (
            <ResponseModal close={responseModal.close} state={responseModal.state} />
          )}
        </div>
      </PageWrapper>
    );
  },
);
