import { FLAVOR } from "CONSTANTS";
import {
  ExternalManufacturerItem,
  ExternalManufacturingOrder,
} from "api/external-manufacturing/models";
import { orderActions } from "api/orders/actions";
import { AddOrdersToUnloadingResponse } from "api/orders/models";
import { useScrollableContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { Button } from "components/miloDesignSystem/atoms/button";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader, SlidingHeaderWrapper } from "components/utils/drawer";
import { useStateModal } from "hooks";
import { ResponseModal } from "pages/wms/unloadingsList/rightPanel/components/addOrdersBySignaturesModal/ResponseModal";
import { MdiDownloadCsv } from "../../../../components/miloDesignSystem/atoms/icons/MdiDownloadCsv";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";
import { UUID } from "api/types";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { MdiCancel } from "../../../../components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiRepeat } from "components/miloDesignSystem/atoms/icons/MdiRepeat";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";

interface Props {
  close: () => void;
  id: UUID;
  isCancelled: ExternalManufacturingOrder["isCancelled"];
  signature: string;
  orders: ExternalManufacturerItem["orders"];
}

export const PanelHeader = ({ close, id, isCancelled, signature, orders }: Props) => {
  const { isTriggerElementHidden } = useScrollableContext();
  const createUnloadingMutation = orderActions.useCreateUnloadingFromOrders();
  const responseModal = useStateModal<AddOrdersToUnloadingResponse>();
  return (
    <>
      <RightPanelHeader>
        {isTriggerElementHidden ? (
          <SlidingHeaderWrapper>
            <ChangeCancellationButton id={id} isCancelled={isCancelled} />
            <Typography fontSize="16" fontWeight="600" className="ml-1">
              {signature}
            </Typography>
          </SlidingHeaderWrapper>
        ) : (
          <ChangeCancellationButton id={id} isCancelled={isCancelled} />
        )}
        <div className="d-flex align-items-center justify-content-end flex-1">
          {FLAVOR === "main" && (
            <Button
              className="text-uppercase"
              size="small"
              variant="gray"
              isLoading={createUnloadingMutation.isLoading}
              startIcon={MdiAdd}
              onClick={() => {
                createUnloadingMutation.mutate(
                  { orderIds: [...new Set(orders.map(order => order.id))] },
                  {
                    onSuccess: payload => {
                      responseModal.open(payload);
                    },
                  },
                );
              }}
            >
              Utwórz rozładunek
            </Button>
          )}
          <span className="divider line-divider" />
          <FileDownloadHandler
            factoryFn={() =>
              externalManufacturingFileFactory.externalManufacturingOrderPdf({ id: id, signature })
            }
            type="pdf"
          >
            {({ download, isLoading }) => (
              <IconButton
                className="mr-1"
                isLoading={isLoading}
                icon={MdiQrCode}
                onClick={event => {
                  event.stopPropagation();
                  download();
                }}
                variant="transparent"
              />
            )}
          </FileDownloadHandler>
          {FLAVOR === "main" && (
            <div className="d-flex align-items-center">
              <FileDownloadHandler
                factoryFn={() =>
                  externalManufacturingFileFactory.externalManufacturingOrderCsv([id], signature)
                }
                type="csv"
              >
                {({ download, isLoading }) => (
                  <IconButton
                    icon={MdiDownloadCsv}
                    isLoading={isLoading}
                    onClick={download}
                    variant="transparent"
                  />
                )}
              </FileDownloadHandler>
            </div>
          )}

          <span className="divider line-divider" />

          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {responseModal.isOpen && responseModal.state && (
        <ResponseModal close={responseModal.close} state={responseModal.state} />
      )}
    </>
  );
};

const ChangeCancellationButton = ({
  id,
  isCancelled,
}: {
  id: UUID;
  isCancelled: ExternalManufacturingOrder["isCancelled"];
}) => {
  const changeCancelStatusMutation = externalManufacturingActions.useChangeCancelStatus();

  if (FLAVOR !== "main") return null;

  return (
    <Button
      className="text-uppercase"
      isLoading={changeCancelStatusMutation.isLoading}
      onClick={() =>
        changeCancelStatusMutation.mutate({
          cancel: !isCancelled,
          manufacturingOrderIds: [id],
        })
      }
      size="small"
      startIcon={isCancelled ? MdiRepeat : MdiCancel}
      variant="gray"
    >
      {isCancelled ? "Przywróć zlecenie" : "Anuluj zlecenie"}
    </Button>
  );
};
