import { QueryParams } from "api/types";
import { LongHeldPackageTab } from "api/unique-packages/models";
import { queryString } from "utilities";

type GetSearchProps = {
  query: QueryParams;
  tab: LongHeldPackageTab;
};

export const getSearch = ({ query, tab }: GetSearchProps) => {
  const isSettled = (() => {
    if (tab === LongHeldPackageTab["in-stock"]) {
      return "true";
    }
    if (tab === LongHeldPackageTab["not-in-stock"]) {
      return "false";
    }
    return "";
  })();

  const tabsQuery = {
    isSettled,
  };

  return queryString.stringify({
    ...query,
    ...tabsQuery,
  });
};
