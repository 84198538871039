import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../StageBoard.module.css";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { dateFns, dateUtils } from "utilities";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { CountryCode, channels } from "CONSTANTS";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { ManufacturingItem } from "api/manufacturing/models";
import { getScheduledAtLabel } from "../listView/useUnitColumns";
import { MaterialStageStatus } from "api/manufacturing/units/models";
import { manufacturingItemsConstants } from "constants/manufacturingItemsConstants";
import { OrderTypeChoices } from "api/orders/enums";
import { MdiClaim } from "components/miloDesignSystem/atoms/icons/MdiClaim";
import { MdiComment } from "components/miloDesignSystem/atoms/icons/MdiComment";

export const useManufacturingItemsColumns = () => {
  return useCreateTableColumns<ManufacturingItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "zlecenie",
        size: 190,
        cell: info => {
          const unit = info.getValue();
          return (
            <div className="d-flex align-items-center gap-2">
              {manufacturingStagesUtils.isUnitUrgent(unit.priority) && (
                <div
                  className={styles.marker}
                  style={{
                    background:
                      colorPalette[
                        manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                          unit.priority
                        ].light.color
                      ],
                  }}
                />
              )}
              {manufacturingStagesUtils.isUnitUrgent(unit.priority) && (
                <div
                  className={styles.ticketIcon}
                  style={{
                    borderColor:
                      colorPalette[
                        manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[
                          unit.priority
                        ].light.color
                      ],
                  }}
                >
                  {
                    manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[unit.priority]
                      .light.icon
                  }
                </div>
              )}
              <Typography
                className={styles.ticketSignature}
                color={
                  manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[unit.priority]
                    .light.color
                }
                fontSize="14"
                fontWeight="600"
                noWrap
              >
                {unit.signature}
                {unit.itemNote && (
                  <Tooltip title={unit.itemNote}>
                    <MdiComment color="deepOrange400" size="16" className="mr-1" />
                  </Tooltip>
                )}
                {unit.order?.type === OrderTypeChoices.COMPLAINT && (
                  <MdiClaim color="magenta200" size="16" />
                )}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.externalOrderNumber, {
        header: "nr. zlec. klienta",
        size: 130,
        typographyProps: {
          fontSize: "12",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.name, {
        header: "model",
        size: 200,
        typographyProps: {
          fontSize: "14",
        },
      }),
      columnHelper.accessor(row => row.currentStages, {
        header: "Obecny etap",
        size: 105,
        cell: info => {
          const currentStages = info.getValue();
          if (!Boolean(currentStages.length)) return <EmptyValue fontSize="14" />;
          return (
            <div className="d-flex align-items-center gap-1">
              <Tag
                endIcon={
                  <div className={styles.previousStageIcon}>
                    {manufacturingStagesConstants.stageStatusIconDict[currentStages[0].status]}
                  </div>
                }
                label={currentStages[0].name}
                type="outlined"
                variant="warning"
              />
              {currentStages.length >= 2 && (
                <Typography color="deepPurple400" fontSize="12" fontWeight="700">
                  +{currentStages.length - 1}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.materials, {
        header: "status zam. materiału",
        size: 120,
        cell: info => {
          const materials = info.getValue();
          const customOrderedMaterials = materials.filter(material => material.isCustomOrdered);
          if (!Boolean(customOrderedMaterials.length))
            return <EmptyValue fontSize="14" fontWeight="700" />;

          if (manufacturingStagesUtils.areSomeMaterialsNotOrdered(customOrderedMaterials))
            return (
              <Tag
                label={
                  manufacturingStagesConstants.materialsStatusOptions[
                    MaterialStageStatus.NOT_ORDERED
                  ].label
                }
                variant={
                  manufacturingStagesConstants.materialsStatusOptions[
                    MaterialStageStatus.NOT_ORDERED
                  ].variant
                }
              />
            );

          if (manufacturingStagesUtils.areSomeMaterialsOrdered(customOrderedMaterials))
            return (
              <Tag
                label={
                  manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.ORDERED]
                    .label
                }
                variant={
                  manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.ORDERED]
                    .variant
                }
              />
            );

          return (
            <Tag
              label={
                manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.RECEIVED]
                  .label
              }
              variant={
                manufacturingStagesConstants.materialsStatusOptions[MaterialStageStatus.RECEIVED]
                  .variant
              }
            />
          );
        },
      }),
      columnHelper.accessor(row => row.materials, {
        header: "materiał",
        size: 140,
        cell: info => {
          const materials = info.getValue();
          if (!Boolean(materials.length)) return <EmptyValue fontSize="14" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1 cut-text">
              <Typography fontSize="14" fontWeight="600" noWrap>
                {materials.map(material => material.name).join(", ")}
              </Typography>
            </div>
          );
        },
      }),
      // columnHelper.accessor(row => row.employee, {
      //   header: "przyp.",
      //   size: 40,
      //   cell: info => {
      //     const employee = info.getValue();
      //     if (!employee) return <EmptyValue fontSize="14" fontWeight="700" />;
      //     return <Avatar size="sm" theme="light" user={employee} />;
      //   },
      // }),
      columnHelper.accessor(row => row.status, {
        header: "status",
        size: 92,
        cell: info => {
          const status = info.getValue();
          return manufacturingItemsConstants.getStatusContants(status).tag;
        },
      }),
      columnHelper.text(
        row => {
          if (!row.startedAt) return null;
          return dateUtils.formatDateToDisplayDateAndTime(row.startedAt);
        },
        {
          header: "w etapie od",
          size: 110,
        },
      ),
      columnHelper.accessor(row => row.scheduledAt, {
        header: "wyprodukować do",
        size: 120,
        cell: info => {
          const scheduledAt = info.getValue();
          if (!scheduledAt) return <EmptyValue fontSize="14" fontWeight="700" />;

          const scheduledAtLabel = getScheduledAtLabel(scheduledAt);

          return (
            <div className="d-flex align-items-center gap-1">
              <Typography fontSize="14" fontWeight="600">
                {dateFns.format(new Date(scheduledAt), "d.MM")}
              </Typography>
              {scheduledAtLabel && (
                <Typography color={scheduledAtLabel.color} fontSize="14" fontWeight="600">
                  ({scheduledAtLabel.label})
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.locations?.join(", ") || null, {
        header: "lokalizacja",
        size: 110,
      }),
      columnHelper.link({
        header: "trasa/grupa",
        size: 110,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
        textRenderer(row) {
          if (row.route) return row.route.signature;
          if (row.orderGroup) return row.orderGroup.signature;
        },
        to: row => {
          if (row.route) return `/routes/list/active?panelId=${row.route.id}`;
          if (row.orderGroup) return `/order-groups/list/orders?panelId=${row.orderGroup.id}`;
        },
      }),
      columnHelper.accessor(row => row.countryCode, {
        header: "kraj wysyłki",
        size: 70,
        cell: info => {
          const countryCode = info.getValue();
          if (!countryCode) return <EmptyValue fontSize="14" fontWeight="700" />;
          return <CountryFlag countryCode={countryCode as CountryCode} />;
        },
      }),
      columnHelper.text(row => row.order?.signature, {
        header: "zamówienie",
        size: 130,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.salesAccount, {
        header: "konto sprzedażowe",
        size: 115,
        cell: info => {
          const salesAccount = info.getValue();
          if (!salesAccount) return <EmptyValue fontSize="14" fontWeight="500" />;
          return (
            <div>
              {channels[salesAccount.channel]?.icon ? (
                <img alt="marketplace" src={channels[salesAccount.channel]?.icon} />
              ) : (
                <Typography fontSize="12" fontWeight="700" noWrap>
                  {salesAccount.name}
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "additionalActions",
        header: " ",
        size: 66,
        cell: info => {
          const unit = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <FileDownloadHandler
                factoryFn={() =>
                  manufacturingFileFactory.manufacturingItemPdf([unit.id], unit.signature)
                }
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <Tooltip title="Pobierz etykietę">
                    <IconButton
                      icon={MdiQrCode}
                      isLoading={isLoading}
                      onClick={event => {
                        event.stopPropagation();
                        download();
                      }}
                      variant="transparent"
                    />
                  </Tooltip>
                )}
              </FileDownloadHandler>
            </div>
          );
        },
      }),
    ];
  });
};
