import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { OrderTypeChoices } from "api/orders/enums";
import { ISODateTime, UUID } from "api/types";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheckSmall } from "components/miloDesignSystem/atoms/icons/MdiCheckSmall";
import { LongHeldPackage } from "api/unique-packages/models";
import styles from "./LongHeldPackages.module.css";
import rowStyles from "components/miloDesignSystem/molecules/table/components/body/components/row/Row.module.css";
import { Link } from "components/miloDesignSystem/atoms/link";
import { cx, dateUtils } from "utilities";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { uniquePackagesActions } from "api/unique-packages/actions";
import { LONG_HELD_PACKAGES_ROW_HEIGHT } from "./LongHeldPackages";
import { ReleasedBySourcesType } from "api/unique-packages/enums";

export type NormalizedLongHeldPackage = {
  packageGroupId: LongHeldPackage["id"];
  indexName: string | null;
  order: {
    id: number;
    signature: string;
    externalId: string;
  } | null;
  settledAt: ISODateTime | null;
  createdAt: ISODateTime | null;
  packageLength: number;
  shouldBeSpanned: boolean;
  customer: string | null;
  productId: UUID;
} & LongHeldPackage["packages"][number];

export const normalizeLongHeldPackages = (data: LongHeldPackage[]): NormalizedLongHeldPackage[] => {
  return data.flatMap(packageGroup => {
    return packageGroup.packages.map((_package, index) => ({
      daysInStock: _package.daysInStock,
      id: _package.id,
      packageGroupId: packageGroup.id,
      indexName: packageGroup.index?.name || packageGroup.index?.productName || null,
      isDelivered: _package.isDelivered,
      location: _package.location,
      order: packageGroup.order,
      receivedAt: _package.receivedAt,
      releasedAt: _package.releasedAt,
      createdAt: packageGroup.createdAt,
      settledAt: _package.settledAt,
      packageLength: packageGroup.packages.length,
      shouldBeSpanned: index === 0,
      receivedBySource: _package.receivedBySource,
      releasedBySource: _package.releasedBySource,
      customer: packageGroup.customer?.name || null,
      productId: packageGroup.id,
    })) as NormalizedLongHeldPackage[];
  });
};

export const useLongHeldPackagesColumns = (
  selectedRows: NormalizedLongHeldPackage[],
  hoveredRow: string,
  setHoveredRow: (hoveredRow: string) => void,
) => {
  const isInSelectedRows = (id: UUID) =>
    Boolean(selectedRows.some(selectedRow => selectedRow.id === id));

  const spannedRowProps = (row: NormalizedLongHeldPackage) => ({
    onMouseEnter: () => setHoveredRow(row.productId),
    onMouseLeave: () => setHoveredRow(""),
    className: cx(styles.spannedColumn, {
      [rowStyles.selected]: isInSelectedRows(row.id),
      [styles.hoveredBg]: hoveredRow === row.productId,
    }),
    style: { height: getHeight(row) },
  });

  return useCreateTableColumns<NormalizedLongHeldPackage>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        header: "produkt",
        size: 282,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div
              {...spannedRowProps(row)}
              className={cx(spannedRowProps(row).className, styles.productNameColumn)}
            >
              <Typography fontSize="16" fontWeight="700" noWrap>
                {row.indexName || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zamówienie",
        size: 130,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div {...spannedRowProps(row)}>
              <div onClick={event => event.stopPropagation()}>
                {row.order ? (
                  <Link
                    fontSize="14"
                    fontWeight="500"
                    to={getOrderRedirectUrl({
                      id: row.order.id,
                      isCanceled: false,
                      isSettled: false,
                      signature: row.order.signature,
                      type: OrderTypeChoices.STANDARD,
                    })}
                  >
                    {row.order.signature}
                  </Link>
                ) : (
                  <EmptyValue fontSize="14" fontWeight="500" />
                )}
              </div>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "nr obcy zamówienia",
        size: 110,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div {...spannedRowProps(row)}>
              <Typography fontSize="14" fontWeight="500" noWrap>
                {row.order?.externalId || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "kontrahent",
        size: 120,
        cell: info => {
          const row = info.getValue();

          if (!row.shouldBeSpanned) return <div></div>;

          return (
            <div {...spannedRowProps(row)}>
              <Typography fontSize="14" fontWeight="500" noWrap>
                {row.customer || EMPTY_VALUE}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "opłacono",
        size: 64,
        cell: info => {
          const row = info.getValue();
          if (!row.shouldBeSpanned) return <div></div>;
          return (
            <div
              {...spannedRowProps(row)}
              className={cx(
                spannedRowProps(row).className,
                styles.settledColumn,
                "d-flex align-items-center justify-content-center",
              )}
              onClick={e => e.stopPropagation()}
            >
              <SettleCheckbox row={row} />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.settledAt, {
        header: "czy rozliczono?",
        size: 90,
        cell: info => {
          const settledAt = info.getValue();
          if (settledAt) {
            return <Tag label="TAK" startIcon={MdiCheckSmall} variant="success" />;
          }
          return <Tag label="NIE" variant="warning" />;
        },
      }),
      columnHelper.date(row => row.settledAt, {
        header: "kiedy rozliczono",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.date(row => row.receivedAt, {
        header: "kiedy przyjęte",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "700" },
      }),
      columnHelper.link({
        header: "odbiór (rozładunek)",
        size: 120,
        textRenderer: row => row.receivedBySource?.details.signature,
        to: row => `/wms/unloadings/list/all?panelId=${row.receivedBySource?.details.id}`,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      // columnHelper.accessor(row => row, {
      //   header: "l. przyjętych paczek",
      //   size: 110,
      //   cell: info => {
      //     const row = info.getValue();
      //     return (
      //       <div className="d-flex align-items-center gap-1">
      //         <Typography fontSize="14" fontWeight="700" color="success500">
      //           {row}
      //         </Typography>
      //         <Typography fontSize="14" fontWeight="700">
      //           z {row}
      //         </Typography>
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.date(row => row.releasedAt, {
        header: "kiedy wydane",
        size: 98,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
      columnHelper.link({
        header: "wysyłka (załadunek)",
        size: 120,
        textRenderer: row => row.releasedBySource?.details.signature,
        to: row =>
          row.releasedBySource ? getReleasedBySourceLinkBasedOnType(row.releasedBySource) : "",
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.daysInStock, {
        header: "l. dni",
        size: 60,
        cell: info => {
          const daysInStock = info.getValue();
          return (
            <Typography fontSize="14" fontWeight="500" noWrap>
              {dateUtils.parseIsoDuration(daysInStock).days}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.isDelivered, {
        header: "doręczenie",
        size: 100,
        cell: info => {
          const isDelivered = info.getValue();
          if (isDelivered) {
            return (
              <Tag label="DORECZONO" startIcon={MdiCheckSmall} variant="success" type="outlined" />
            );
          }
          return <Tag label="NIE DORĘCZONO" variant="quaternary" type="outlined" />;
        },
      }),
      columnHelper.text(row => row.location, {
        header: "lokalizacja",
        size: 110,
        typographyProps: { fontSize: "14", fontWeight: "600" },
      }),
      columnHelper.date(row => row.createdAt, {
        header: "utworzono",
        size: 100,
        typographyProps: { fontSize: "14", fontWeight: "500" },
      }),
    ];
  });
};

const SettleCheckbox = ({ row }: { row: NormalizedLongHeldPackage }) => {
  const mutation = uniquePackagesActions.usePatchGroup();
  return (
    <Checkbox
      checked={Boolean(row.settledAt)}
      disabled={mutation.isLoading}
      onChange={isSettled => mutation.mutate({ id: row.packageGroupId, isSettled })}
      size="sm"
    />
  );
};
const getHeight = (row: NormalizedLongHeldPackage) => {
  const bordersHeight = row.packageLength === 1 ? 0 : row.packageLength;

  return `${Number(LONG_HELD_PACKAGES_ROW_HEIGHT) * row.packageLength + bordersHeight}px`;
};

export const getReleasedBySourceLinkBasedOnType = (
  releasedBySources: NonNullable<LongHeldPackage["packages"][number]["releasedBySource"]>,
) => {
  switch (releasedBySources.type) {
    case ReleasedBySourcesType.LOADING:
      return `/wms/loadings/list/all?panelId=${releasedBySources.details.id}`;
    case ReleasedBySourcesType.WH_ENTRY_DOCUMENT:
      return `/warehouse/documents?panelId=${releasedBySources.details.id}`;

    default:
      const exhaustiveCheck: never = releasedBySources.type;
      console.error(`Unhandled releasedBySources type: ${exhaustiveCheck}`);
      return "";
  }
};
