import { ExternalManufacturingOrder } from "api/external-manufacturing/models";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CreatedAtLabel } from "pages/externalManufacturerPanel/shared/components/CreatedAtLabel";
import { PriorityLabel } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { ProgressBar } from "components/miloDesignSystem/atoms/progressBar";
import styles from "pages/externalManufacturerPanel/shared/ExternalManufacturerPanel.module.css";
import { cx, dateUtils } from "utilities";
import { OrderStatusLabel } from "pages/externalManufacturerPanel/shared/components/OrderStatusLabel";
import { EmailSendingStatus } from "pages/externalManufacturerPanel/shared/components/EmailSendingStatus";
import { EstimatedTimeOfSendingEmail } from "pages/externalManufacturerPanel/shared/components/EstimatedTimeOfSendingEmail";
import { FulFillmentDate } from "pages/externalManufacturerPanel/shared/components/FulfillmentDate";
import { OrderedByAvatars } from "pages/externalManufacturerPanel/shared/components/OrderedByAvatars";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler";
import { externalManufacturingFileFactory } from "api/external-manufacturing/api";

export const useExternalManufacturingOrdersColumns = () => {
  return useCreateTableColumns<ExternalManufacturingOrder>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        typographyProps: {
          fontSize: "14",
        },
        size: 130,
      }),
      columnHelper.accessor(row => row, {
        id: "isCancelled",
        header: " ",
        size: 80,
        cell: info => {
          const order = info.getValue();
          if (!order.isCancelled) return null;
          return <Tag label="anulowane" variant="warning" />;
        },
      }),
      columnHelper.accessor(row => row.createdAt, {
        header: "utworzono",
        size: 130,
        cell: info => {
          const createdAt = info.getValue();
          return <CreatedAtLabel createdAt={createdAt} fontSize="12" fontWeight="600" />;
        },
      }),
      columnHelper.text(row => row.manufacturer?.name, {
        header: "producent",
        size: 130,
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: "",
        size: 20,
        cell: info => {
          const priority = info.getValue();
          return <PriorityLabel priority={priority} />;
        },
      }),
      columnHelper.accessor(row => row.lineItems.total, {
        id: "numOfLineItemsTotal",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            l. mebli
          </Typography>
        ),
        size: 50,
        cell: info => {
          const total = info.getValue();
          return (
            <Typography className="text-right w-100" fontSize="12" fontWeight="700">
              {total}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.progress, {
        header: "progres realizacji",
        size: 140,
        cell: info => {
          const progress = info.getValue();
          return (
            <div className={cx(styles.progressBarContainer, "d-flex align-items-center gap-2")}>
              <ProgressBar progress={progress} />
              <Typography fontSize="12" fontWeight="700">
                {progress.toFixed()}%
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.orderStatus, {
        header: "status",
        size: 100,
        cell: info => {
          const status = info.getValue();
          return <OrderStatusLabel status={status} />;
        },
      }),
      columnHelper.accessor(row => row.emailSentStatus, {
        header: "status email",
        size: 100,
        cell: info => {
          const status = info.getValue();
          return <EmailSendingStatus status={status} />;
        },
      }),
      columnHelper.accessor(row => row.estimatedTimeOfSendingEmail, {
        header: "planowana wysyłka",
        size: 130,
        cell: info => {
          const date = info.getValue();
          return (
            <EstimatedTimeOfSendingEmail
              estimatedTimeOfSendingEmail={date}
              fontSize="12"
              fontWeight="600"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.fulfillmentAt, {
        header: "realizacja",
        size: 130,
        cell: info => {
          const date = info.getValue();
          return <FulFillmentDate fontSize="12" fontWeight="600" fulfillmentDate={date} />;
        },
      }),
      columnHelper.accessor(
        row =>
          row.deliveredToWarehouseAt
            ? dateUtils.formatDateToDisplayOnlyDate(row.deliveredToWarehouseAt)
            : null,
        {
          header: "dostawa do magazynu",
          size: 130,
          cell: info => {
            const date = info.getValue();
            return (
              <div className="d-flex align-items-center gap-1">
                <Typography fontSize="12" fontWeight="600">
                  {date ?? EMPTY_VALUE}
                </Typography>
                {date && <Tag label="OK" variant="success" />}
              </div>
            );
          },
        },
      ),
      columnHelper.text(
        row =>
          row.expectedWarehouseDeliveryDate
            ? dateUtils.formatDateToDisplayOnlyDate(row.expectedWarehouseDeliveryDate)
            : null,
        {
          header: "odbiór",
          size: 100,
        },
      ),
      columnHelper.accessor(row => row.orderedBy, {
        header: "zlecono przez",
        size: 130,
        cell: info => {
          const orderedBy = info.getValue();
          return <OrderedByAvatars orderedBy={orderedBy} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const order = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <FileDownloadHandler
                factoryFn={() =>
                  externalManufacturingFileFactory.externalManufacturingOrderPdf(order)
                }
                type="pdf"
              >
                {({ download, isLoading }) => (
                  <IconButton
                    isLoading={isLoading}
                    icon={MdiQrCode}
                    onClick={event => {
                      event.stopPropagation();
                      download();
                    }}
                    variant="gray"
                  />
                )}
              </FileDownloadHandler>
            </div>
          );
        },
      }),
    ];
  });
};
