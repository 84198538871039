import { UUID } from "api/types";
import { useTableKeyboard } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableKeyboard";
import { TableProps } from "components/miloDesignSystem/molecules/table/types";
import { useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../LongHeldPackages.module.css";
import { cx } from "utilities";

export const useMultiSelect = <T extends { id: UUID | number; productId: UUID | number }>({
  rows,
}: Pick<TableProps<T>, "rows">) => {
  const [selectedRows, setSelectedRows] = useState<T[]>([]);
  useTableKeyboard({ rows, setSelectedRows });

  const onRowClick = (row: T, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (ctrlOrMetaKey(event)) {
      event.preventDefault();
      window.getSelection()?.empty();
      handleSelection({ rowId: row.id.toString(), rows, setSelectedRows });
      return;
    }

    if (event.shiftKey) {
      event.preventDefault();
      window.getSelection()?.empty();
      handleSelectionFromTo({ rowId: row.id, rows, selectedRows, setSelectedRows });
      return;
    }

    const selectedRow = rows.find(_row => String(_row.id) === String(row.id));
    assertIsDefined(selectedRow);
    const rowsToSelect = rows.filter(_row => _row.productId === selectedRow.productId);
    setSelectedRows(rowsToSelect);
    return;
  };

  const overrides: TableProps<T>["overrides"] = () => {
    return {
      row: row => {
        const isRowSelected = selectedRows.find(selectedRow => selectedRow.id === row.id);

        return {
          className: cx({
            [styles.selected]: isRowSelected,
          }),
        };
      },
    };
  };

  return {
    selectedRows,
    clear: () => setSelectedRows([]),
    onRowClick,
    overrides,
  };
};

const handleSelection = <T extends { id: UUID | number; productId: UUID | number }>({
  rowId,
  rows,
  setSelectedRows,
}: {
  rowId: T["id"];
  rows: T[];
  setSelectedRows: (value: React.SetStateAction<T[]>) => void;
}) => {
  setSelectedRows(selectedRows => {
    const selectedRow = rows.find(row => String(row.id) === rowId);
    assertIsDefined(selectedRow);
    const rowsToSelect = rows.filter(
      row => String(row.productId) === String(selectedRow.productId),
    );
    const areAlreadyAdded = Boolean(
      rowsToSelect.every(rowToSelect =>
        selectedRows.find(selectedRow => String(selectedRow.id) === String(rowToSelect.id)),
      ),
    );
    if (areAlreadyAdded) {
      return selectedRows.filter(
        selectedRow =>
          !rowsToSelect.find(rowToSelect => String(rowToSelect.id) === String(selectedRow.id)),
      );
    }
    return [...selectedRows, ...rowsToSelect];
  });
};

const handleSelectionFromTo = <T extends { id: UUID | number; productId: UUID | number }>({
  rowId,
  rows,
  selectedRows,
  setSelectedRows,
}: {
  rowId: T["id"];
  rows: T[];
  selectedRows: T[];
  setSelectedRows: (value: React.SetStateAction<T[]>) => void;
}) => {
  if (!selectedRows.length) {
    const selectedRow = rows.find(row => String(row.id) === rowId);
    assertIsDefined(selectedRow);
    const rowsToSelect = rows.filter(_row => _row.productId === selectedRow.productId);
    setSelectedRows(rowsToSelect);
    return;
  }

  const lastSelectedRowIndex = rows.findIndex(
    row => String(row.id) === String(selectedRows[selectedRows.length - 1].id),
  );

  const selectedRow = rows.find(row => String(row.id) === rowId);
  assertIsDefined(selectedRow);
  const rowsToSelect = rows.filter(row => String(row.productId) === selectedRow.productId);
  const rowsToSelectIndexes = rows
    .map((row, index) =>
      rowsToSelect.some(rowToSelect => String(rowToSelect.id) === String(row.id)) ? index : -1,
    )
    .filter(index => index !== -1);
  const isLastSelectedRowGreaterThanCurrent = Boolean(
    rowsToSelectIndexes.some(index => index > lastSelectedRowIndex),
  );
  const rowToSelectIndex = rows.findIndex(
    row =>
      String(row.id) ===
      String(rowsToSelect[isLastSelectedRowGreaterThanCurrent ? rowsToSelect.length - 1 : 0].id),
  );

  const [start, end] = [lastSelectedRowIndex, rowToSelectIndex].sort((a, b) => a - b);

  const inBetween = rows.slice(start, end + 1);

  const rowsToAdd = inBetween.filter(
    (el): boolean => !selectedRows.find(selectedItem => selectedItem.id === el.id),
  );

  return setSelectedRows([...selectedRows, ...rowsToAdd]);
};

const ctrlOrMetaKey = (event: React.MouseEvent | KeyboardEvent) => {
  const isUsingMacPlatform = navigator.userAgent.includes("Mac");
  return isUsingMacPlatform ? event.metaKey : event.ctrlKey;
};
