import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ExecutionStatus } from "./components/ExecutionStatus";
import { EstimatedDelivery } from "./components/EstimatedDelivery";
import { dateUtils } from "utilities";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { OrderLineItemListItem, OrderStatus } from "api/external-manufacturing/models";
import { PriorityLabel } from "pages/externalManufacturerPanel/shared/components/PriorityLabel";
import { CreatedAtLabel } from "pages/externalManufacturerPanel/shared/components/CreatedAtLabel";
import { channels, Channels } from "CONSTANTS";
import { Link } from "components/miloDesignSystem/atoms/link";
import { externalManufacturingActions } from "api/external-manufacturing/actions";
import { PickedUpBy } from "pages/externalManufacturerPanel/shared/components/PickedUpBy";
import { DeliveryDeadline } from "pages/externalManufacturerPanel/shared/components/DeliveryDeadline";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import { isEqual } from "date-fns";
import { countryToFlagDict } from "constants/countriesFlags";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { useGetMiloSettings } from "api/milo-settings/hooks";

export const useOrderLineItemColumns = () => {
  const handleDownloadExternalManufacturingLineItemLabelPdf = externalManufacturingActions.useDownloadExternalManufacturingLineItemsLabelsPdf();
  const { data } = useGetMiloSettings("production");

  return useCreateTableColumns<OrderLineItemListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 120,
      }),
      columnHelper.accessor(row => row, {
        header: " ",
        size: 80,
        cell: info => {
          const lineItem = info.getValue();
          if (lineItem.isNew && !lineItem.isCancelled) return <Tag label="nowe" variant="info" />;
          if (lineItem.isCancelled) return <Tag label="anulowane" variant="warning" />;
          return null;
        },
      }),
      columnHelper.text(row => row.name, {
        header: "produkt",
        size: 320,
        typographyProps: { noWrap: "2", fontWeight: "600" },
      }),
      columnHelper.accessor(row => row.product, {
        header: "atrybuty",
        size: 250,
        cell: info => {
          const product: OrderLineItemListItem["product"] = info.getValue();
          if (!product || !Boolean(product.attributes.length)) return <EmptyValue />;
          return (
            <Attributes
              attributes={product.attributes.map(attribute => ({
                id: attribute.id,
                label: attribute.name,
                value: attribute.value,
              }))}
            />
          );
        },
      }),
      columnHelper.text(row => row.ean, {
        header: "EAN",
        size: 100,
      }),
      columnHelper.accessor(row => row.order, {
        header: "zamówienie",
        size: 120,
        cell: info => {
          const order = info.getValue();
          return (
            <div onClick={event => event.stopPropagation()}>
              <Link
                fontSize="12"
                fontWeight="700"
                to={`/orders/active/list/all?panelId=${order.id}`}
              >
                {order.signature}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: "",
        size: 25,
        cell: info => {
          const priority = info.getValue();
          return <PriorityLabel priority={priority} />;
        },
      }),
      data?.isManualExternalManufacturingItemStatusChangeAllowed
        ? columnHelper.accessor(row => row, {
            id: "receivedAt",
            header: () => (
              <Typography
                className="text-center"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                zlecono
              </Typography>
            ),
            size: 60,
            cell: info => {
              const row = info.getValue();
              return (
                <div
                  className="d-flex align-items-center justify-content-center flex-1"
                  onClick={e => e.stopPropagation()}
                >
                  <Checkbox.Async
                    label=""
                    checked={row.orderStatus === OrderStatus.SENT}
                    disabled={row.orderStatus === OrderStatus.SENT}
                    mutationHook={externalManufacturingActions.usePatchOrderLineItem}
                    transformQueryData={() => ({
                      id: row.id,
                      toUpdate: { orderStatus: OrderStatus.SENT },
                    })}
                    size="sx"
                  />
                </div>
              );
            },
          })
        : undefined,
      columnHelper.text(row => row.manufacturerName, {
        header: "nazwa producenta",
        size: 110,
      }),
      columnHelper.accessor(row => row.address, {
        header: "kraj",
        size: 60,
        cell: info => {
          const address = info.getValue();
          if (!Boolean(address) || !address.countryCode || !Boolean(address.countryCode.length))
            return <EmptyValue />;
          return (
            <div className="d-flex align-items-center gap-1">
              <img alt="Flaga kraju" src={countryToFlagDict[address.countryCode]} />
              <Typography fontSize="12" fontWeight="700">
                {address.countryCode}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.manufacturer.name, {
        header: "producent",
        size: 130,
      }),
      columnHelper.accessor(row => row.salesChannel, {
        header: "kanał sprzedaży",
        size: 100,
        cell: info => {
          const salesChannel: OrderLineItemListItem["salesChannel"] = info.getValue();
          const salesChannelName = salesChannel.name.toLowerCase() as Channels;
          return (
            <Typography.WithCustomColor
              fontSize="12"
              fontWeight="700"
              color={
                channels[salesChannelName] !== undefined
                  ? channels[salesChannelName].color
                  : "neutralBlack100"
              }
            >
              {channels[salesChannelName] !== undefined
                ? channels[salesChannelName].name
                : salesChannelName}
            </Typography.WithCustomColor>
          );
        },
      }),
      columnHelper.accessor(row => row.executionStatus, {
        header: "status realizacji",
        size: 110,
        cell: info => {
          const status = info.getValue();
          return <ExecutionStatus status={status} />;
        },
      }),
      columnHelper.text(row => "---", {
        header: "status odbioru",
        size: 85,
      }),
      columnHelper.text(
        row =>
          row.fulfillmentAt ? dateUtils.formatDateToDisplayOnlyDate(row.fulfillmentAt) : null,
        {
          header: "termin",
          size: 90,
        },
      ),
      columnHelper.accessor(row => row.manufacturingOrder, {
        header: "ZDP",
        size: 110,
        cell: info => {
          const order = info.getValue();
          return (
            <div onClick={event => event.stopPropagation()}>
              <Link
                fontSize="12"
                fontWeight="700"
                to={`/external-manufacturing/orders/list/all?panelId=${order.id}`}
              >
                {order.signature}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row.deliveryGroup, {
        header: "trasa",
        size: 110,
        cell: info => {
          const deliveryGroup = info.getValue();
          if (!Boolean(deliveryGroup.signature)) return <EmptyValue />;
          return (
            <div onClick={event => event.stopPropagation()}>
              <Link
                fontSize="12"
                fontWeight="700"
                to={
                  deliveryGroup.kind === "ROUTE"
                    ? `/routes/list/active?panelId=${deliveryGroup.id}`
                    : `/order-groups/list/orders?panelId=${deliveryGroup.id}`
                }
              >
                {deliveryGroup.signature}
              </Link>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "załadunek",
        size: 100,
        cell: info => {
          const lineItem: OrderLineItemListItem = info.getValue();
          const loadingDate = lineItem.deliveryGroup.loadingDate;
          const pickUpDate = lineItem.shippingInfo?.pickedUpAt;
          if (!loadingDate) return <EmptyValue />;
          if (pickUpDate && new Date(pickUpDate) > new Date(loadingDate))
            return (
              <Tag label={dateUtils.formatDateToDisplayOnlyDate(loadingDate)} variant="danger" />
            );
          if (pickUpDate && isEqual(new Date(pickUpDate), new Date(loadingDate)))
            return (
              <Tag label={dateUtils.formatDateToDisplayOnlyDate(loadingDate)} variant="warning" />
            );
          return (
            <Tag label={dateUtils.formatDateToDisplayOnlyDate(loadingDate)} variant="outline" />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "planowy odbiór",
        size: 180,
        cell: info => {
          const lineItem: OrderLineItemListItem = info.getValue();
          if (!lineItem.shippingInfo) return <EmptyValue />;
          if (lineItem.shippingInfo.pickedUpBySeller && lineItem.shippingInfo.pickedUpAt)
            return (
              <Typography fontSize="14" fontWeight="700">
                {dateUtils.formatDateToDisplayOnlyDate(lineItem.shippingInfo.pickedUpAt)}
              </Typography>
            );
          return (
            <EstimatedDelivery
              estimatedDelivery={lineItem.shippingInfo.pickupBySellerPlannedAt}
              fontSize="12"
              fontWeight="400"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.deliveryDeadlineDate, {
        header: "dostarczyć do:",
        size: 160,
        cell: info => {
          const date: string | null = info.getValue();
          if (!date) return <EmptyValue />;
          return <DeliveryDeadline date={date} />;
        },
      }),
      columnHelper.text(
        row =>
          row.shippingInfo && row.shippingInfo.pickedUpAt
            ? dateUtils.formatDateToDisplayOnlyDate(row.shippingInfo.pickedUpAt)
            : null,
        {
          header: "odebrano",
          size: 100,
        },
      ),
      columnHelper.accessor(row => row, {
        header: "kto odbiera?",
        size: 130,
        cell: info => {
          const lineItem: OrderLineItemListItem = info.getValue();
          return <PickedUpBy shipment={lineItem.shippingInfo} />;
        },
      }),
      columnHelper.accessor(row => row.orderedAt, {
        header: "zlecono",
        size: 100,
        cell: info => {
          const orderedAt = info.getValue();
          if (!orderedAt) return <EmptyValue />;
          return <CreatedAtLabel createdAt={orderedAt} fontSize="12" fontWeight="600" />;
        },
      }),
      columnHelper.accessor(row => row.orderedBy, {
        header: "zlecono przez",
        size: 85,
        cell: info => {
          const user = info.getValue();
          return (
            <div className="w-100 d-flex justify-content-center">
              <Avatar size="sm" theme="light" user={user} />
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "etykiety",
        size: 43,
        cell: info => {
          const lineItem = info.getValue();
          return (
            <div className="d-flex justify-content-center w-100">
              <IconButton
                icon={<MdiQrCode size="16" />}
                onClick={event => {
                  event.stopPropagation();
                  handleDownloadExternalManufacturingLineItemLabelPdf(
                    lineItem.id,
                    lineItem.signature,
                  );
                }}
                variant="gray"
              />
            </div>
          );
        },
      }),
    ];
  });
};
